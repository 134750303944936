import { Link, useLocation } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from '../navigation/LanguageSwitcher';
import { Button } from '~/components/common/buttons/Button';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import { isLocationHomepage } from '~/components/utils/location';
import { useLanguageParam } from '~/config/i18n';

const Copyright = () => {
  const { t } = useTranslation();
  const lang = useLanguageParam();
  const location = useLocation();
  const isHomepage = isLocationHomepage(location);

  return (
    <>
      <div className="container container-md d-flex flex-lg-row flex-column-reverse justify-content-between align-items-center gap-6 pt-5 pb-36 pb-md-5">
        <div className="d-lg-flex me-4">
          <p className="d-flex caption "></p>
          <div className="caption d-flex flex-wrap gap-2 -regular color-primary justify-content-center align-items-center justify-content-md-start me-4">
            <span>
              {t('footer.copyright').replace('[year]', new Date().getFullYear().toString())}{' '}
              {t('footer.version').replace('[version]', 'v2.41.0')}
            </span>
            <span>•</span>
            <button
              className="text-decoration-underline link-underline-primary link-offset-2 link-underline-opacity-0 link-underline-opacity-50-hover"
              onClick={() => {
                window.open(t('global.linkPaths.privacyPolicy'), '_blank');
              }}
            >
              <span className="-regular ">{t('global.links.privacyPolicy')}</span>
            </button>
            <span>•</span>
            <button
              className="text-decoration-underline link-underline-primary link-offset-2 link-underline-opacity-0 link-underline-opacity-50-hover"
              onClick={() => {
                window.open(t('global.linkPaths.termsAndConditions'), '_blank');
              }}
            >
              <span className="-regular ">{t('global.links.termsAndConditions')}</span>
            </button>
            <span>•</span>
            {isHomepage ? (
              <Link
                className="text-decoration-underline link-underline-primary link-offset-2 link-underline-opacity-0 link-underline-opacity-50-hover"
                target="_self"
                to={`/${lang}/sitemap`}
              >
                <span className="-regular ">{t('global.links.sitemap')}</span>
              </Link>
            ) : (
              <button
                className="text-decoration-underline link-underline-primary link-offset-2 link-underline-opacity-0 link-underline-opacity-50-hover"
                onClick={() => {
                  window.open(`/${lang}/sitemap`, '_self');
                }}
              >
                <span className="-regular ">{t('global.links.sitemap')}</span>
              </button>
            )}
          </div>
        </div>
        <div className="d-flex gap-6 align-items-center">
          <LanguageSwitcher
            variant={'primaryLight'}
            showLabel={true}
            buttonSize="sm"
          />
          <Button
            variant={'primaryLight'}
            extraClasses={`p-0 border-0`}
            isIconButton={true}
            onClick={() => window.open('https://www.linkedin.com/company/fiveoffices/', '_blank')}
          >
            <SVGIcon
              iconType={'linkedin'}
              className="m-0"
            />
          </Button>
        </div>
      </div>
    </>
  );
};

export default Copyright;
